import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { finalize, takeUntil } from 'rxjs/operators';
import {
  CoreHashedKey,
  FormElementViewsSelector,
  ViewsFieldVboSelectionMode,
  ViewsPluginRequest,
  ViewsVboDefaultStartupParameter,
  ViewsVboPreconfiguratedItem,
  ViewsVboSelectedItem
} from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { AbstractDecoupledModalComponent } from '../../../../decoupled-modal/models/abstract-decoupled-modal.component';
import { ViewsuserconfigchangedEventdata } from '../../../../list_v2/viewsuserconfigchanged.eventdata';
import { FormManagerService } from '../../../form-manager/form-manager.service';
import { FieldConfig } from '../../../interfaces/field-config.interface';
import { ViewsFormComponent } from '../../../shared/view-form/views-form.component';
import { isNullOrUndefined, JsonPathTryEvaluateNew } from '../../../../utils/typescript.utils';
import { ViewsinitializedEventdata } from '../../../../list_v2/events/viewsinitialized.eventdata';

/**
 * Modal que se usa en el ViewsSelector component
 */
@Component({
  selector: 'app-viewsselectormodal',
  templateUrl: './viewsselectormodal.component.html'
})
export class ViewsselectormodalComponent
    extends AbstractDecoupledModalComponent implements OnInit {

  /**
   * The List2Component reference.
   */
  @ViewChild('listField', {static: true}) listField: ViewsFormComponent;

  /**
   * The form manager
   */
  @Input() formManager: FormManagerService;

  /**
   * Element's field configuration
   */
  @Input() configuration: FieldConfig;

  /**
   * Selected keys
   */
  @Input() selectedKeys: CoreHashedKey[];

  /**
   * Result of the modal
   */
  @Output() onSelect: EventEmitter<CoreHashedKey[]> = new EventEmitter<CoreHashedKey[]>();

  /**
   * The title of the list
   */
  title: string = '';

  /**
   * @inheritdoc
   */
  ngOnInit(): void {
    this.valueChangedHandler();
  }

  vboChanged(): void {
    if (this.configuration.isMultiselect === true) {
      return;
    }
    // Cuando no es multiselect (solo permite una opción) al momento de cambiar el valor
    // del VBO lanzo la selección.
    this.selectItems();
  }

  selectItems(): void {
    this.listField.materializeVboSelectionInMemory()
        .pipe(
            takeUntil(this.componentDestroyed$),
            finalize(() => {
              this.closeModal();
            }))
        .subscribe((items: ViewsVboSelectedItem[]) => {
          const values: CoreHashedKey[] = items.map((i) => i.HashedKey);
          this.onSelect.emit(values);
        });
  }

  /**
   * Handler for a value changed event
   */
  valueChangedHandler(): void {
    const eventData: ViewsuserconfigchangedEventdata = new ViewsuserconfigchangedEventdata(null);
    this.listField.loadListFromPluginRequest(this.pluginRequest, true, eventData);
  }

  /**
   * Returns the view ID
   * @returns {string}
   */
  get pluginRequest(): ViewsPluginRequest {
    const config: FormElementViewsSelector = this.configuration.FormElement as FormElementViewsSelector;
    const pluginRequest: ViewsPluginRequest = new ViewsPluginRequest();

    pluginRequest.Id = config.ViewsId;
    pluginRequest.Arguments = pluginRequest.Arguments || {} as any;

    // Legacy: pasamos el formulario completo por si el receptor quiere usarlo. Aquí lo recomendable
    // es utilizar un selector JPATH en el valor del argumento para que se envíe al destinatario
    // solo la info que necesita
    pluginRequest.Arguments['formValues'] = this.formManager.getForm().getRawValue();

    // Al preparar los argumentos para el formulario, vamos a tratar de expandirlos usando los
    // valores del formulario
    if (!isNullOrUndefined(config.ViewsArguments)) {
      for (const argumentName of Object.keys(config.ViewsArguments)) {
        const argumentValue: string = config.ViewsArguments[argumentName];
        JsonPathTryEvaluateNew<string>(
            argumentValue,
            pluginRequest.Arguments['formValues'],
            (value) => {
              pluginRequest.Arguments[argumentName] = value;
            },
            (e) => {
              pluginRequest.Arguments[argumentName] = argumentValue;
            });
      }
    }

    // TODO Revisar por David
    // A los argumentso que vengan de backend, le metemos una copia entera del formulario, con el objetivo
    // de poder recibir el estado actual del formulario en frontend
    pluginRequest.Parameters = config.ViewsParameters;
    const vboStartupParam: ViewsVboDefaultStartupParameter = new ViewsVboDefaultStartupParameter();
    vboStartupParam.FieldId = this.configuration.ClientPath;
    vboStartupParam.Identifiers = this.selectedKeys.map((i) => Object.assign(new ViewsVboPreconfiguratedItem(), {
      Key: i,
      Selected: true
    }));
    vboStartupParam.SelectionMode = this.configuration.isMultiselect === false ? ViewsFieldVboSelectionMode.SingleButton : ViewsFieldVboSelectionMode.Multiple;
    if (isNullOrUndefined(pluginRequest.Parameters)) {
      pluginRequest.Parameters = {};
    }
    pluginRequest.Parameters['vbo*'] = vboStartupParam;
    return pluginRequest;
  }

  /**
   * Get the title of the list and set to this component
   * @param event
   */
  initialize(event: ViewsinitializedEventdata): void {
    this.title = event.configuration.Title;
    this.listField.hideTitle();
  }
}
