<mwl-calendar-month-view  *ngIf="weekendDays.length != 7"
  [viewDate]="viewDate"
  [events]="events"
  [refresh]="refresh"
  [activeDayIsOpen]="activeDayIsOpen"
  [weekendDays]="weekendDays"
  [excludeDays]="weekendDays"
  [weekStartsOn]="weekStartOn"
  (dayClicked)="onDayClicked($event)"
  (eventClicked)="onEventClicked($event)"
  (eventTimesChanged)="onEventTimesChanged($event)"
  (beforeViewRender)="onBeforeMonthViewRender($event)">
</mwl-calendar-month-view>
<div  *ngIf="weekendDays.length == 7"> No existen dias laborables </div>
