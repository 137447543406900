import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SkipSelf,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors } from '@angular/forms';
import { debounceTime, delay, pairwise, startWith, takeUntil } from 'rxjs/operators';
import { merge, Subject } from 'rxjs';
import {
  FormConfiguration,
  FormElement,
  FormElementAction,
  FormElementInput,
  FormState,
  FormSubmitType,
  FormValidationError
} from '../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { isNullOrUndefined, isNullOrWhitespace, UtilsTypescript } from '../../utils/typescript.utils';
import { ButtonClickedEventData } from '../form-components/button/buttonclicked.eventdata';
import { FrontendFormElementInput } from '../form-components/formelementinput.class';
import { FrontendFormElementWrapper } from '../form-components/formelementwrapper.class';
import { FormManagerService } from '../form-manager/form-manager.service';
import { FieldConfig } from '../interfaces/field-config.interface';
import { ValuechangedEventdata } from '../valuechanged.eventdata';

import { Guid } from 'guid-typescript';
import { WindowRef } from '../../utils/browser-globals';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';


@Component({
  selector: 'app-form',
  templateUrl: './form.component.html'
})
export class FormComponent extends FrontendFormElementWrapper implements OnChanges, OnInit, AfterViewInit, OnDestroy {

  @Input() title = '';
  @Input() formPlugin = '';
  @Input() subtitle = '';

  @Output() canceled: EventEmitter<boolean> = new EventEmitter<boolean>();

  // Evento si cambia cualquier valor del formulario
  @Output() changedValue: EventEmitter<ValuechangedEventdata> = new EventEmitter<ValuechangedEventdata>();
  protected changedValueDebouncer: Subject<ValuechangedEventdata> = new Subject<ValuechangedEventdata>();

  @Output() afterInit: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('anchor', {static: true}) anchor: ElementRef;

  /**
   * Row config used to build the components
   */
  rows: { [key: number]: FieldConfig[] } = [];

  formElementInstance(): FrontendFormElementInput {
    throw new Error('Not supported for ' + this.config.ClientPath);
  }

  @HostBinding('class')
  get hostWrapperClasses(): string {
    const classes: string[] = this.getComponentClasses();
    // Workaround para el uso en backend de StyleConstants.SIZE_FORM
    if (!classes.find((i) => i.indexOf('flex-col') !== -1)) {
      classes.push('flex-col-sm-12 flex-col-md-12 flex-col-lg-12');
    }
    const result: string = classes.join(' ');
    return result;
  }

  /**
   * Obtiene el combinado de mensajes de validación entre backend y frontend, que
   * debe mostrarse como agregado en el formulario
   */
  get validationMessages(): FormValidationError[] {

    const errors: ValidationErrors = {};

    const formState: FormState = this.formManagerService.getFormState();

    let allErrors: FormValidationError[] = [];

    // Importante que los de frontend vengan primero, porque los de backend
    // deberían pisar los mensajes con las mismas claves
    if (this.formManagerService.submitAttemptWithClientValidationErrors.value) {
      allErrors = [...allErrors, ...this.formManagerService.submitAttemptWithClientValidationErrors.value];
    }

    if (formState && formState.ValidationErrors) {
      allErrors = [...allErrors, ...formState.ValidationErrors];
    }

    // Empezamos con los errores de frontend que hubo en el último envío
    for (const err of allErrors) {
      if (err.Element) {
        const formElementConfig: FormElement = this.formManagerService.getConfigFromSelector(err.Element);
        if ((formElementConfig as FormElementInput).DoNotPropagateValidationErrorsToFormSummary === true) {
          continue;
        }
      }
      const validationKey: string = isNullOrWhitespace(err.ValidationKey) ? Guid.create().toString() : err.ValidationKey;
      const key: string = err.Element + '::' + validationKey;
      errors[key] = err;
    }

    return Object.values(errors);
  }

  /**
   * Get an instance of FormComponent
   *
   * @param {FormBuilder} fb
   */
  constructor(protected fb: FormBuilder,
              protected cdRef: ChangeDetectorRef,
              @SkipSelf() protected cdRefParent: ChangeDetectorRef,
              public formManagerService: FormManagerService,
              protected windowRef: WindowRef) {

    super(formManagerService, cdRef, cdRefParent);

    this.group = this.fb.group({});
    this.formManagerService.registerForm(this.group, this.cdRef);

    // Usamos el debouncer para que primero se procesen internamente todos
    // los eventos relacionados con la API de estados, y ninguna manipulacion
    // externa pueda rompar los flujos.
    this.changedValueDebouncer
        .pipe(
            delay(100),
            takeUntil(this.componentDestroyed$)
        )
        .subscribe(((val): void => this.changedValue.emit(val)).bind(this));

    // Escuchamos al evento de botón presionado
    this.formManagerService
        .buttonClicked
        .pipe(
            delay(100),
            takeUntil(this.componentDestroyed$)
        )
        .subscribe(((val): void => this.clickHandler(val)).bind(this));

    // Diferentes observables que pueden implicar un cambio en los mensjes
    merge(
        this.formManagerService.form.statusChanges,
        this.formManagerService.formStateChanged,
        this.formManagerService.submitAttemptWithClientValidationErrors)
        .pipe(
            debounceTime(50),
            takeUntil(this.componentDestroyed$)
        )
        .subscribe(((val): void => this.detectChanges()));
  }

  /**
   * Sobercargamos para tener clases específicas del componente de formulario
   */
  getComponentClasses(): string[] {
    const classes: string[] = super.getComponentClasses();
    classes.push('form-' + this.formManagerService.getFormState().FormId)
    if ((this.config.FormElement as FormConfiguration).DisableBootstrapColumns === false) {
      classes.push('flex-col-sm-12 flex-col-md-12 flex-col-lg-12');
    }
    return classes;
  }

  ngOnInit(): void {
    if (!this.formPlugin || this.formPlugin === '') {
      this.formPlugin = this.formManagerService.getFormState().FormId;
    }
    const formElement: FormElement = this.formManagerService.getConfigFromSelector('');
    this.formManagerService.materializeControlGroup(formElement, this.group, this.rows);
    this.formManagerService.materializationComplete.next(true);
  }

  ngOnChanges(): void {
  }

  /**
   * Recursively register changed event listeners
   */
  private registerControlChangedValueListeners(formGroup: FormGroup, path: string, formRawValue: any): void {
    Object.keys(formGroup.controls)
        .map(c => {
          const controlPath: string = (UtilsTypescript.isNullOrWhitespace(path) ? '' : path + '.') + c;
          const control: AbstractControl = formGroup.controls[c];

          if (control instanceof FormGroup) {
            this.registerControlChangedValueListeners(control as FormGroup, controlPath, formRawValue);
            return;
          }

          control.valueChanges
              .pipe(
                  takeUntil(this.componentDestroyed$),
                  startWith(this.formManagerService.getFormComponentValueRaw(controlPath, formRawValue)),
                  pairwise()
              )
              .subscribe(
                  (([prev, next]): void => {
                    const currentValue: any = prev;
                    const bothNull: any = isNullOrUndefined(currentValue) && isNullOrUndefined(next);
                    // Usamos stringify porque sino los componentes con valor com plejo (i.e. autocompletes)
                    // utilizarian comparacion de instancia, lo que no detectaria correctamente los cambios.
                    if (!bothNull && (JSON.stringify(currentValue) !== JSON.stringify(next))) {
                      const eventArgs: ValuechangedEventdata = new ValuechangedEventdata();
                      eventArgs.name = c;
                      eventArgs.prevValue = currentValue;
                      eventArgs.newValue = next;
                      eventArgs.path = controlPath;
                      this.changedValueDebouncer.next(eventArgs);
                    }
                  }).bind(this));
        });
  }

  /***
   *
   */
  ngAfterViewInit(): void {

    // Como se genera dinámicamente el contenido del formulario, hay que hacer
    // un detect changes global una vez se ha terminado de montar la vista.
    this.formManagerService.detachWrapperChangeDetectors();
    this.cdRef.detach();

    // Colgarnos de los eventos de cambio de cada uno de los controles para propagarlos
    // a nivel de formulario, lo hacemos aquí para garantizar que están todos ya construidos
    // porque los de los fieldset se construyen después del nginit()
    this.registerControlChangedValueListeners(this.group, null, this.group.getRawValue());

    // Esto se ha puesto aquí ya que la construcción de componentes está delegada
    // también a los fieldsets, y la única garantía de que están todos construidos
    // la tenemos aquí.
    this.formManagerService.formStateApiManager.optimizeAndSubscribe();
    this.formManagerService.preserveValueManager.initialize();

    // Al hacer scroll to top
    this.formManagerService
        .scrollToTop
        .pipe(
            debounceTime(75),
            takeUntil(this.componentDestroyed$)
        )
        .subscribe(((): void => {
          this.doScrollToTop();
        }).bind(this));

    // Verifica si se ha deshabilitado el foco automatico desde la configuración
    // del formulario en back-end.
    if (!(this.config.FormElement as FormConfiguration).DisableAutomaticElementFocus) {
      // Poner el foco en el primer elemento disponible
      this.formManagerService.focusFirstAvailableFormElement();
    }

    // Ejecuta las validaciones de cliente al iniciar el formulario
    if ((this.config.FormElement as FormConfiguration).RunClientSideValidationOnInit) {
      fromPromise(this.formManagerService.verifyAndUpdateLastSubmitClientSideValidations())
          .pipe(
              takeUntil(this.componentDestroyed$)
          )
    }

    this.afterInit.emit();
  }

  /**
   * El scroll efectivo
   */
  private doScrollToTop(): void {
    if (this.anchor && this.anchor.nativeElement) {
      this.anchor.nativeElement.scrollIntoView();
      // TODO: Estos 75 px son para compensar el header del layout ACHS,
      // debería venir del layout...
      this.windowRef.getNativeWindow().scrollBy(0, -75);
    }
  }

  /**
   * Handling of any button clicked
   * @param event
   */
  clickHandler(event: ButtonClickedEventData): void {
    switch (event.Action) {
      case FormElementAction.Submit:
        // Para bloquear la validación de cliente y mejorar la UX, ésta solo se ejecutará
        // en caso de no tenga errores de servidor recibidos, porque una vez que se mezclan
        // erorres de servidor y de cliente el flujo es confuso para el usuario, y en ese
        // caso lo mejor es hacer solo la de servidor
        const requireClientSideValidationsBeforeSubmit: boolean = event.RequireClientValidations === true && !this.formManagerService.getFormState().ValidationErrors;
        if (requireClientSideValidationsBeforeSubmit) {
          fromPromise(this.formManagerService.verifyAndUpdateLastSubmitClientSideValidations())
              .pipe(
                  takeUntil(this.componentDestroyed$)
              )
              .subscribe((i) => {
                if (i === true) {
                  this.formManagerService.submitForm(event.Emitter, FormSubmitType.Normal);
                } else {
                  this.formManagerService.scrollToTop.next(null);
                }
              });
        } else {
          this.formManagerService.submitForm(event.Emitter, FormSubmitType.Normal);
        }
        break;
      case FormElementAction.Cancel:
        this.canceled.emit();
        break;
      case FormElementAction.OtherAction:
      default:
        if (isNullOrUndefined(event.Metadata)) {
          this.formManagerService.formEvent.emit(event.Emitter);
        } else {
          this.formManagerService.formEventComplex.emit({id: null, emiter: event.Emitter, metadata: event.Metadata});
        }
        break;
    }
  }
}
