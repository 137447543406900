import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  BsDatepickerConfig,
  BsDatepickerDirective,
  BsDaterangepickerConfig, BsDaterangepickerDirective,
  BsLocaleService
} from 'ngx-bootstrap/datepicker';
import { ChangedetectorReference } from '../../../../../../core/changedetector/changedetectoreference';
import {
  ViewFieldData,
  ViewFilterSimple,
  ViewFilterSimpleOperatorConfiguration,
  ViewFilterSimpleOperatorType,
  ViewsDataTypeConfiguration,
  ViewsFieldTypeConfigEnum,
  ViewsFieldTypeConfigEnumOption, ViewsFieldTypeConfigSimpleType,
  ViewsFieldTypeEnum
} from '../../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import {
  AbstractDecoupledModalComponent
} from '../../../../../decoupled-modal/models/abstract-decoupled-modal.component';
import { getInSafe, isNullOrUndefined, mapDictionary } from '../../../../../utils/typescript.utils';
import {
  NgxDatepickerChangedetectionFixerDirective
} from '../../../../../../sabentisutils/ngx-datepicker-changedetection-fixer.directive';
import { mergeMap, takeUntil } from 'rxjs/operators';
import {
  NgxDaterangepickerChangedetectionFixerDirective
} from '../../../../../../sabentisutils/ngx-daterangepicker-changedetection-fixer.directive';
import {ViewUtils} from "../../../../view.utils";

@Component({
  selector: 'app-view-filter-modal',
  templateUrl: './view-filter-modal.component.html',
  styleUrls: ['./view-filter-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    ChangedetectorReference
  ]
})
export class ViewFilterModalComponent
    extends AbstractDecoupledModalComponent implements OnInit, AfterViewInit {

  /**
   * Selected Fields
   */
  @Input() fields: { [key: string]: ViewFilterSimple };

  /**
   * Operators
   */
  @Input() operators: { [key: string]: ViewFilterSimpleOperatorConfiguration };

  /**
   * Operators configuration to match
   */
  @Input() operatorsForType: ViewsDataTypeConfiguration[];

  /**
   * Original Fields
   */
  @Input() originalFields: { [key: string]: ViewFieldData };

  @ViewChild(BsDatepickerDirective, {static: true})
  protected inputElementBsDatepicker: BsDatepickerDirective;

  @ViewChild(NgxDatepickerChangedetectionFixerDirective, {static: true})
  protected ngxDatepickerChangedetectionFixerDirective: NgxDatepickerChangedetectionFixerDirective;

  @ViewChild(BsDaterangepickerDirective, {static: true})
  protected inputElementBsDaterangepicker: BsDaterangepickerDirective;

  @ViewChild(NgxDaterangepickerChangedetectionFixerDirective, {static: true})
  protected ngxDaterangepickerChangedetectionFixerDirective: NgxDaterangepickerChangedetectionFixerDirective;

  /**
   * available operators for each selection
   */
  avaliableOperators: ViewFilterSimpleOperatorConfiguration[] = [];

  /**
   * Configuration of DatePicker
   */
  bsConfigDatepicker: BsDatepickerConfig;

  /**
   * Configuration of DateRangePicker
   */
  bsConfigDaterangepicker: BsDaterangepickerConfig;

  /**
   * Color theme for DatePicker and DateRangePicker
   */
  colorTheme = 'theme-green';

  /**
   * The form
   */
  @ViewChildren('filterForm')
  filterForm: QueryList<NgForm>;

  onConfigurationSet: EventEmitter<any> = new EventEmitter();

  /**
   * Get an instance of ViewFilterModalComponent
   */
  constructor(
      private bsLocaleService: BsLocaleService,
      private cdReference: ChangedetectorReference
  ) {
    super();
    this.bsLocaleService.use('es');
  }

  /**
   * Component initialization
   */
  ngOnInit(): void {

    for (const k in this.operators) {
      if (this.operators.hasOwnProperty(k)) {
        this.avaliableOperators.push(this.operators[k])
      }
    }

    if (!isNullOrUndefined(this.ngxDatepickerChangedetectionFixerDirective)) {
      this.ngxDatepickerChangedetectionFixerDirective.bsDatepicker = this.inputElementBsDatepicker;
    }

    if (!isNullOrUndefined(this.ngxDaterangepickerChangedetectionFixerDirective)) {
      this.ngxDaterangepickerChangedetectionFixerDirective.bsDaterangepicker = this.inputElementBsDaterangepicker;
    }

    this.bsConfigDatepicker = new BsDatepickerConfig();
    this.bsConfigDatepicker.containerClass = this.colorTheme;
    this.bsConfigDatepicker.showWeekNumbers = false;
    this.bsConfigDatepicker.dateInputFormat = 'DD/MM/YYYY';

    this.bsConfigDaterangepicker = new BsDaterangepickerConfig();
    this.bsConfigDaterangepicker.containerClass = this.colorTheme;
    this.bsConfigDaterangepicker.showWeekNumbers = false;
    this.bsConfigDaterangepicker.rangeInputFormat = 'DD/MM/YYYY';
  }


  ngAfterViewInit(): void {
    this.filterForm
        .changes
        .pipe(
            takeUntil(this.componentDestroyed$),
            mergeMap((comps: QueryList<NgForm>) => comps.first.statusChanges),
            takeUntil(this.componentDestroyed$))
        .subscribe(() => {
          this.cdReference.detectChangesIfOutsideAngular();
        });
  }

  /**
   * Field Selector changed. Recalculates operators
   * @param {NgForm} filterForm
   */
  fieldSelectionChanged(filterForm: NgForm): void {
    const currentField: ViewFieldData = this.getOriginalFieldFromFilterForm(filterForm);
    const currentOperator: ViewsDataTypeConfiguration = this.operatorsForType
        .find(x => x.DataType === currentField.TypeConfiguration.Type);

    filterForm.form.get('operator').reset();

    this.avaliableOperators = [];

    if (isNullOrUndefined(currentOperator)) {
      return;
    }

    for (const k in this.operators) {
      if (this.operators.hasOwnProperty(k)) {
        if (currentOperator.Operators.findIndex(x => x === this.operators[k].Type) !== -1) {
          this.avaliableOperators.push(this.operators[k])
        }
      }
    }
    filterForm.form.get('operator').setValue(this.avaliableOperators[0].Type.toString());
    this.cdReference.detectChangesIfOutsideAngular();
  }

  /**
   * get options for enum selector
   * @param {NgForm} filterForm
   * @returns {any}
   */
  getOptions(filterForm: NgForm): Array<ViewsFieldTypeConfigEnumOption> {
    const f: ViewFieldData = this.getOriginalFieldFromFilterForm(filterForm);
    let options: any = getInSafe(f, (i) => (i.TypeConfiguration as ViewsFieldTypeConfigEnum).Options, {});
    if ((isNullOrUndefined(options) || Object.keys(options).length == 0) && this.isBoolean(filterForm))
    {
      let source = f.FieldFormatters["bool"];
      options = ViewUtils.BooleanOptions;
      options['0'].DisplayName = source["FalseText"];
      options['1'].DisplayName = source["TrueText"];
    }
    return mapDictionary(options);
  }

  /**
   * returns original fields from a given form state
   * @param {NgForm} filterForm
   * @returns {ViewFieldData}
   */
  getOriginalFieldFromFilterForm(filterForm: NgForm): ViewFieldData {
    return Object.keys(this.originalFields).map((key) => {
      return this.originalFields[key]
    }).find(x => x.Id === filterForm.value['field'])
  }

  /**
   * Check if operator is "in range" and needs to values
   * @param {NgForm} filterForm
   * @returns {boolean}
   */
  hasSecondValue(filterForm: NgForm): boolean {
    return filterForm.value['operator'] === ViewFilterSimpleOperatorType.InRange.toString();
  }

  /**
   * Check if operator is "empty" and not needs values
   * @param {NgForm} filterForm
   * @returns {boolean}
   */
  isNotEmpty(filterForm: NgForm): boolean {
    return filterForm.value['operator'] !== ViewFilterSimpleOperatorType.Empty.toString();
  }

  /**
   * method to hide modal
   */
  hideModal(): void {
    this.closeModal();
  }

  /**
   * checks if field is date
   * @param {NgForm} filterForm
   * @returns {boolean}
   */
  isDate(filterForm: NgForm): boolean {
    if (!filterForm.value.hasOwnProperty('field')) {
      return false;
    }
    const f: ViewFieldData = this.getOriginalFieldFromFilterForm(filterForm);
    if (isNullOrUndefined(f)) {
      return false;
    }
    return f.TypeConfiguration.Type === ViewsFieldTypeEnum.Date;
  }

  /**
   * Check when components (except field component) needs to be disabled
   * @param {NgForm} filterForm
   * @returns {boolean}
   */
  isDisabled(filterForm: NgForm): boolean {
    return !filterForm.value.hasOwnProperty('field') || filterForm.value['field'] === '';
  }

  logForm(filterForm: NgForm): void {
    console.debug(filterForm);
  }

  /**
   * checks if field is enum
   * @param {NgForm} filterForm
   * @returns {boolean}
   */
  isEnum(filterForm: NgForm): boolean {
    if (!filterForm.value.hasOwnProperty('field')) {
      return false;
    }
    const f: ViewFieldData = this.getOriginalFieldFromFilterForm(filterForm);
    if (isNullOrUndefined(f)) {
      return false;
    }
    return f.TypeConfiguration.Type === ViewsFieldTypeEnum.Enum;
  }

  /**
   * checks if field is number
   * @param {NgForm} filterForm
   * @returns {boolean}
   */
  isNumber(filterForm: NgForm): boolean {
    if (!filterForm.value.hasOwnProperty('field')) {
      return false;
    }
    const f: ViewFieldData = this.getOriginalFieldFromFilterForm(filterForm);
    if (isNullOrUndefined(f)) {
      return false;
    }
    return f.TypeConfiguration.Type === ViewsFieldTypeEnum.Number;
  }

  /**
   * checks if field is text
   * @param {NgForm} filterForm
   * @returns {boolean}
   */
  isText(filterForm: NgForm): boolean {
    if (!filterForm.value.hasOwnProperty('field')) {
      return false;
    }
    const f: ViewFieldData = this.getOriginalFieldFromFilterForm(filterForm);
    if (isNullOrUndefined(f)) {
      return false;
    }
    return f.TypeConfiguration.Type === ViewsFieldTypeEnum.String;
  }

  /**
   * checks if field is boolean
   * @param {NgForm} filterForm
   * @returns {boolean}
   */
  isBoolean(filterForm: NgForm): boolean {
    if (!filterForm.value.hasOwnProperty('field')) {
      return false;
    }
    const f: ViewFieldData = this.getOriginalFieldFromFilterForm(filterForm);
    if (isNullOrUndefined(f)) {
      return false;
    }
    return f.TypeConfiguration.Type === ViewsFieldTypeEnum.Boolean;
  }

  /**
   * checks if operator is ContainedInCsv
   * @param {NgForm} filterForm
   * @returns {boolean}
   */
  isContainedInCsv(filterForm: NgForm): boolean {
    if (!filterForm.form.value.hasOwnProperty('operator')) {
      return false;
    }
    const test: any = filterForm.form.value['operator'];
    return filterForm.form.value['operator'] === ViewFilterSimpleOperatorType.ContainedInCsv.toString();
  }

  /**
   * hidden handler
   */
  onHidden(): void {
    this.closeModal(false);
  }

  /**
   * Save and close modal
   * @param {NgForm} form
   */
  saveAndHideModal(form: NgForm): void {
    const values: any = form.value;
    this.onConfigurationSet.emit(values);
    this.closeModal(true);
  }
}
