import { ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';

import {
  Coordinates,
  FormElementOption,
  GeocodeResult,
  WebServiceResponse
} from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { FrontendFormElementInput } from '../../formelementinput.class';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { catchError, delay, filter, mergeMap, take, takeUntil, tap, throttleTime } from 'rxjs/operators';
import { NEVER, Observable, of, throwError } from 'rxjs';
import { backendTypeMatch, getInSafe, getNewtonSoftRealValue } from '../../../../utils/typescript.utils';
import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';
import { FormManagerService } from '../../../form-manager/form-manager.service';
import { FrontendGeocodingService } from '../../../../geocoding/frontend-geocoding.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../../../../../core/authentication/auth.service';
import { TranslatorService } from '../../../../../core/translator/services/rest-translator.service';

/**
 * Map component used on form API.
 */
@Component({
  selector: 'app-maps-inner-input',
  templateUrl: './maps.input.component.html',
  styleUrls: ['./maps-input.component.scss'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => MapsInputComponent), multi: true}
  ]
})
export class MapsInputComponent
    extends FrontendFormElementInput implements OnInit {
  /**
   * Loading spinnner boolean.
   */
  loading = false;

  /**
   * The Google Maps API is loaded
   */
  googleMapsApiLoaded = false;

  /**
   * When true, all value change events are processed.
   */
  takeValues: boolean = true;

  /**
   * Si hay un error de permisos para acceder, se usa para evitar que se muestre el listado.
   */
  authError: boolean = false;

  /**
   * Hide the component if the user does not have permissions to load the view
   */
  @Input() hideOn403: boolean = true;

  /**
   * Value
   */
  private geoValue: Coordinates;

  /**
   * Initial zoom for map
   */
  zoom: number = 15;

  constructor(protected formManagerService: FormManagerService,
              protected cdRef: ChangeDetectorRef,
              protected geocodingService: FrontendGeocodingService,
              private auth: AuthService,
              protected localeService: TranslatorService) {
    super(formManagerService, cdRef, localeService);
    this.geocodingService
        .apiLoaded
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(x => {
              this.googleMapsApiLoaded = x;
            }
        )
  }

  ngOnInit(): void {
    if (!this.value) {
      this.value = new GeocodeResult();
      this.value.Latitude = this.geocodingService.defaultLatitude;
      this.value.Longitude = this.geocodingService.defaultLongitude;
    }
    super.ngOnInit();
    if (this.config.addessMappingObject) {
      Object.keys(this.config.addessMappingObject)
          .map((item) => {
            const selector: string = this.config.addessMappingObject[item];
            this.manageAddressSubscriptions(selector, this.group, false);
          });
      if (!isNullOrUndefined(this.config.latitudeElementKey)) {
        this.manageAddressSubscriptions(this.config.latitudeElementKey, this.group, true);
      }
      if (!isNullOrUndefined(this.config.longitudeElementKey)) {
        this.manageAddressSubscriptions(this.config.longitudeElementKey, this.group, true);
      }
    }
  }

  set value(value: Coordinates) {
    this.geoValue = value;
    this.propagateChange(value);
  }

  get value(): Coordinates {
    return this.geoValue;
  }

  get position(): google.maps.LatLngLiteral {
    if (isNullOrUndefined(this.geoValue)) {
      return null;
    }
    return {
      lat: this.geoValue.Latitude,
      lng: this.geoValue.Longitude
    };
  }

  /**
   * Cada opción tiene una clave única combiación.
   *
   * @param option
   */
  getOptionUniqueKey(option: FormElementOption): string {
    return this.uniqueComponentId(option.Key + '_');
  }

  writeValue(obj: GeocodeResult | Coordinates): void {
    if (!obj) {
      this.value = new GeocodeResult();
      this.value.Latitude = this.geocodingService.defaultLatitude;
      this.value.Longitude = this.geocodingService.defaultLongitude;
      return;
    }

    if (backendTypeMatch('Coordinates', obj)) {
      this.value = obj;
      this.geoValue = obj;
      this.loading = false;
      this.cdRef.detectChanges();
      return;
    }

    if (backendTypeMatch(GeocodeResult.$type, obj)) {
      const coordinate: Coordinates = new Coordinates();
      coordinate.Latitude = obj.Latitude;
      coordinate.Longitude = obj.Longitude;
      this.loading = false;
      this.cdRef.detectChanges();
      return;
    }

    this.triggerUpdateCycle(of(null).pipe(take(1)), false);
  }

  /**
   * Manage address subscriptions with the geocoding service
   * @param {string} selector
   * @param {FormGroup} group
   */
  manageAddressSubscriptions(selector: string, group: FormGroup, updateValueFromLatLngComponents: boolean): void {
    if (selector.split('.').length !== 1) {
      const nestedSelector: string = selector
          .split('.').slice(1).join('.');
      const nestedGroup: FormGroup = group
          .controls[selector.split('.')[0]] as FormGroup;

      this.manageAddressSubscriptions(nestedSelector, nestedGroup, updateValueFromLatLngComponents);
      return;
    }

    if (!group.controls[selector]) {
      return;
    }
    const controlChanges$: Observable<unknown> = group.controls[selector]
        .valueChanges
        .pipe(
            mergeMap(() => {
              if (this.takeValues) {
                return of(true);
              }

              return of(false);
            }),
            filter((proceed) => proceed),
            throttleTime(100));

    this.triggerUpdateCycle(controlChanges$, updateValueFromLatLngComponents);
  }

  /**
   * Trigger a map update cycle.
   *
   * @param {Observable<unknow>} trigger$
   */
  triggerUpdateCycle(trigger$: Observable<unknown>, updateValueFromLatLngComponents: boolean): void {
    if (updateValueFromLatLngComponents) {
      trigger$
          .pipe(
              takeUntil(this.componentDestroyed$),
              tap(() => {
                const coordinate: Coordinates = new Coordinates();
                coordinate.Latitude = parseFloat(this.formManagerService.getFormComponentValue(this.config.latitudeElementKey));
                coordinate.Longitude = parseFloat(this.formManagerService.getFormComponentValue(this.config.longitudeElementKey));
                this.value = coordinate;
                this.loading = false;
                this.cdRef.detectChanges();
              }),
              delay(1000))
          .subscribe(x => {
          });
      return;
    }

    trigger$
        .pipe(
            mergeMap(() => {
              this.loading = true;
              this.cdRef.detectChanges();

              const adressToSearch: string = this.getAddressFromFields();

              if (isNullOrUndefined(adressToSearch) || ((typeof adressToSearch === 'string') && adressToSearch.length === 0)) {
                return of([]);
              }

              return this.geocodingService.Geocode(adressToSearch);
            }),
            takeUntil(this.componentDestroyed$),
            catchError((i) => {
              if (i instanceof HttpErrorResponse) {
                // Acceso denegado....
                // TODO: Ver que hacía esto antes del cambio de sesión
                // if (i.status === 403 && this.hideOn403 === true && !this.auth.existToken()) {
                if ((i.status === 403 || i.status === 401) && this.hideOn403 === true) {
                  const wsResponse: WebServiceResponse = getInSafe((i), (x) => x.error, null);
                  console.warn(wsResponse.error.message);
                  this.authError = true;
                  this.loading = false;
                  this.cdRef.detectChanges();
                  return NEVER;
                }
              }
              return throwError(i);
            })
        )
        .subscribe((result: GeocodeResult[]) => {
          const values: GeocodeResult[] = getNewtonSoftRealValue(result);

          if (isNullOrUndefined(values) || values.length === 0) {
            this.loading = false;
            this.cdRef.detectChanges();
            return;
          }

          const value: GeocodeResult = values.shift();
          const coordinate: Coordinates = new Coordinates();
          coordinate.Latitude = value.Latitude;
          coordinate.Longitude = value.Longitude;
          this.value = coordinate;

          /*
          Hay que comprobar que existan los componentes, Access = false....
           */
          if (!isNullOrUndefined(this.config.latitudeElementKey) && this.formManagerService.getFormComponent(this.config.latitudeElementKey)) {
            this.formManagerService.setFormComponentValue(this.config.latitudeElementKey, coordinate.Latitude);
          }
          if (!isNullOrUndefined(this.config.longitudeElementKey) && this.formManagerService.getFormComponent(this.config.longitudeElementKey)) {
            this.formManagerService.setFormComponentValue(this.config.longitudeElementKey, coordinate.Longitude);
          }

          this.loading = false;

          this.cdRef.detectChanges();
        });
  }

  /***
   * address parsing from input fields.
   * @returns {string} address on string format
   */
  getAddressFromFields(): string {
    let result: string = this.getValueFromAddressSelector('route');

    if (!result) {
      return null;
    }

    const selectors: string[] = ['street_number', 'postal_code', 'locality', 'country'];
    selectors.forEach((x: string) => {
      const value: string = this.getValueFromAddressSelector(x);
      if (value) {
        result += ` ${value}`
      }

    });
    return result;
  }

  getValueFromAddressSelector(selector: string): string {
    if (!this.config.addessMappingObject || !this.config.addessMappingObject.hasOwnProperty(selector)) {
      return null;
    }
    const formElementSelector: string = this.config.addessMappingObject[selector];
    if (!this.formManagerService.getFormComponent(formElementSelector)) {
      return null;
    }
    const value: any = this.formManagerService.getFormComponentValue(formElementSelector);
    const valueName: string = value && JSON.parse(JSON.stringify(value)) && JSON.parse(JSON.stringify(value)).Name ? JSON.parse(JSON.stringify(value)).Name : null;
    return value && valueName ? valueName : value;
  }

}
