import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';

import {
  CardBoardFieldPosition,
  IViewModeUserConfigurationColumn,
  IViewModeUserConfigurationColumnsBased,
  ViewModeCardBoards,
  ViewModeCardBoardsField,
  ViewModeCardBoardsUserConfigurationColumn,
  ViewModeTableUserConfigurationColumn,
  ViewResultCellType,
  ViewResultRow,
  ViewUserConfiguration
} from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { ListComponent2Service } from '../../../list.service';
import { Subject } from 'rxjs';
import { ViewsuserconfigchangedEventdata } from '../../../viewsuserconfigchanged.eventdata';
import { IViewModeComponent } from '../../i-view-mode.component';
import { ViewModeUtils } from '../../viewmode.utils';
import { EnumUtils } from '../../../../utils/enum.utils';
import { CommandService } from '../../../../../core/commands/command.service';
import { takeUntil } from 'rxjs/operators';

/**
 * This component will receive the customized configuration of the list by input, and
 * will generate by output the same configuration but with the values modified by the user.
 *
 * @export
 * @class GridComponent component used to show elements of a list.
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-view-card-boards',
  templateUrl: './card-boards.component.html',
  styleUrls: ['./card-boards.component.scss']
})
export class ViewCardBoardsComponent implements OnInit, OnChanges, OnDestroy, IViewModeComponent {

  /**
   * The result of executing a list.
   */
  @Input() data: ViewResultRow[];

  /**
   * Fields on headers
   */
  fields: ViewModeCardBoardsUserConfigurationColumn[];

  isNullOrUndefined = isNullOrUndefined;

  protected destroySubject$ = new Subject<void>();

  public cardPositions: string[];

  /**
   * Tipos de celda
   *
   * @type {ViewResultCellType}
   */
  public ViewResultCellType: any = ViewResultCellType;

  /**
   * Get an instance of GridComponent
   *
   * @param listComponentConfiguration
   */
  constructor(public listComponentConfiguration: ListComponent2Service, private commandService: CommandService, private cdRef: ChangeDetectorRef) {
    this.cardPositions = EnumUtils.GetEnumNames(CardBoardFieldPosition);
  }

  /**
   * Initializes component subscribing to configuration changes
   */
  ngOnInit(): void {
    this.listComponentConfiguration
        .userConfigurationChanged
        .pipe(takeUntil(this.destroySubject$)).subscribe(
        (next: ViewsuserconfigchangedEventdata) => {
          this.updateHeader();
        }
    )
  }

  /**
   * notify when child component change
   *
   */
  ngOnChanges(): void {
    this.updateHeader();
  }

  /**
   * Unsubscribe service emitter on component destruction
   */
  ngOnDestroy(): void {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }

  /**
   * Updates header values
   */
  private updateHeader(): void {
    const userConfig: ViewUserConfiguration = this.listComponentConfiguration.getUserConfiguration();
    if (isNullOrUndefined(userConfig)) {
      this.fields = [];
      return;
    }

    this.fields = (userConfig.CurrentViewMode as IViewModeUserConfigurationColumnsBased).Columns
        .filter((x: ViewModeTableUserConfigurationColumn) => x.Visible === true)
        .map((x: ViewModeTableUserConfigurationColumn) => x)
  }

  /**
   * Resolve css classes for each cell.
   * @param fieldInclude
   * @param rowResult
   */
  resolveFieldClasses(fieldInclude: ViewModeCardBoardsUserConfigurationColumn, rowResult: ViewResultRow): string[] {
    return ViewModeUtils.resolveTableCellClasses(fieldInclude, rowResult, this.listComponentConfiguration);
  }

  /**
   * Resolve css classes for each row.
   * @param {ViewResultRow} rowResult
   * @returns {any}
   */
  resolveRowClasses(rowResult: ViewResultRow): string[] {
    return ViewModeUtils.resolveRowClasses(rowResult);
  }

  /**
   * Check the type of a cell
   * @param fieldInclude
   */
  checkFieldType(row: ViewResultRow, fieldInclude: ViewModeCardBoardsUserConfigurationColumn): string {
    return ViewModeUtils.checkFieldType(row, fieldInclude, this.listComponentConfiguration);
  }

  /**
   * Initialize the Columns
   */
  initialize(): void {
    this.updateHeader();
  }

  CheckFieldInPosition(pos: string, field: ViewModeCardBoardsUserConfigurationColumn): boolean {
    const fieldInConfig: ViewModeCardBoardsField =
        ViewModeUtils.GetViewModeFieldFromViewModeUserConfigurationFieldFromService(
            field,
            this.listComponentConfiguration) as ViewModeCardBoardsField;
    return EnumUtils.GetEnumNameFromValue(fieldInConfig.Position, CardBoardFieldPosition) === pos;
  }

  checkShowHeaderTitles(): boolean {
    const viewModeConfiguration: ViewModeCardBoards = ViewModeUtils.GetCurrentViewModeFromService(this.listComponentConfiguration) as ViewModeCardBoards;
    return viewModeConfiguration.ShowHeaderTitles;
  }

  /**
   * Get all the Fields casted
   * @param columns
   * @constructorViewsViewModesComponent
   */
  private GetColumnsInPosition(columns: IViewModeUserConfigurationColumn[]): ViewModeCardBoardsUserConfigurationColumn[] {
    return columns
        .filter((field: IViewModeUserConfigurationColumn) => field.Visible)
        .map((field: ViewModeCardBoardsUserConfigurationColumn) => {
          return field;
        });
  }

  /**
   * Resolve css classes for each image row.
   * @param {ViewResultRow} rowResult
   * @returns {any}
   */
  checkImageClass(rowResult: ViewResultRow): boolean {
    return ViewModeUtils.resolveRowClasses(rowResult).includes('card-image');
  }

  /**
   * Resolve row operations for each row.
   * @param {ViewResultRow} rowResult
   * @returns {any}
   */
  checkOperationsOnRow(rowResult: ViewResultRow): any {
    ViewModeUtils.resolveRowOperations(rowResult, this.data, this.commandService, this.cdRef);
  }
}
