import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import {
  IViewField,
  IViewsFieldTypeConfig,
  ViewConfiguration,
  ViewFieldData,
  ViewFilterInstanceSimple,
  ViewFilterManagerSimple,
  ViewFilterSimple,
  ViewFilterSimpleOperatorType,
  ViewsFieldTypeConfigEnum,
  ViewsFieldTypeEnum
} from '../../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { ListComponent2Service } from '../../../../list.service';
import {getInSafe, isNullOrWhitespace} from '../../../../../utils/typescript.utils';
import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';
import { ActiveUserConfigurationClass } from '../../../../activeUserConfigurationClass';
import { takeUntil } from 'rxjs/operators';
import {ViewUtils} from "../../../../view.utils";

@Component({
  selector: 'app-view-filter-container',
  templateUrl: './view-filter-container.component.html',
  styleUrls: ['./view-filter-container.component.scss']
})
export class ViewFilterContainerComponent implements OnInit, OnDestroy {
  /**
   * Filters applied
   */
  filters = {};

  protected destroySubject$ = new Subject<void>();

  /**
   * Creates a new instance of FilterContainerComponent
   * @param {ListComponent2Service} listService
   * @param {DatePipe} datePipe
   */
  constructor(private listService: ListComponent2Service, private datePipe: DatePipe) {
  }

  /**
   * Returns a boolean indicating if there is a filter available for the view.
   */
  get anyFilters(): boolean {
    return (Object.keys(this.filters) || []).length > 0
  }

  /**
   * Initializes components subscribing to changes
   */
  ngOnInit(): void {
    this.filters = getInSafe(this.listService.getUserConfiguration(), x => x.Filters, {});
    this
        .listService
        .activeUserConfiguration
        .pipe(
            takeUntil(this.destroySubject$.asObservable()))
        .subscribe(
            (next: ActiveUserConfigurationClass) => {
              this.filters = isNullOrUndefined(next.userConfiguration.Filters) ? {} : next.userConfiguration.Filters;
            }
        );
  }

  /**
   * Destroys list services subscription on component destruction
   */
  ngOnDestroy(): void {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }

  /**
   * Gets filter value from its instance
   * @param {ViewFilterInstanceSimple} f
   * @returns {string}
   */
  getFilterName(f: ViewFilterInstanceSimple): string {
    const filter: ViewFilterSimple = this.listService.getFilterManagerSimple().Filters[f.FilterId];
    return filter.Label;
  }

  /**
   * Gets filter value. if value is a date value, shows it as a date.
   * If the value is not empty, the output format is between "" fot painting it in the template
   * @param {ViewFilterInstanceSimple} f
   * @param {number} numFilter
   * @returns {string}
   */
  getFilterValue(f: ViewFilterInstanceSimple, numFilter: number): string {

    let value: any;
    if (numFilter === 1) {
      value = f.Value;
    } else if (numFilter === 2) {
      value = f.Value2;
    }

    const config: ViewConfiguration = this.listService.getConfiguration();
    const filter: ViewFilterSimple = this.listService.getFilterManagerSimple().Filters[f.FilterId];
    const field: IViewField = config.Fields[filter.Field];
    const fieldData: ViewFieldData  = field as ViewFieldData;
    // En realidad el TypeConfiguration está declarado en el ViewFieldData, pero como pueden haber
    // clases que hereden, y eso pasa en backend (aquí no tenemos instancias reales de las clases... todavía)
    // intentamos coger el atributo como sea...
    const typeConfiguration: IViewsFieldTypeConfig = getInSafe(fieldData, (i) => i.TypeConfiguration, null);
    if (typeConfiguration != null) {
      if (typeConfiguration.Type === ViewsFieldTypeEnum.Enum) {
        const enumType: ViewsFieldTypeConfigEnum = typeConfiguration as ViewsFieldTypeConfigEnum;
        return isNullOrUndefined(value) ? '' : '"'.concat(enumType.Options[value].DisplayName).concat('"');
      }
      if (typeConfiguration.Type === ViewsFieldTypeEnum.Boolean) {
        let source = fieldData.FieldFormatters["bool"];
        return isNullOrUndefined(value) ? '' : '"'.concat(value === '1' ? source["TrueText"] : source["FalseText"]).concat('"');
      }
    }

    if (this.checkIfDate(f) && !isNullOrUndefined(value)) {
      return '"'.concat(this.datePipe.transform(new Date(value * 1000), 'dd/MM/yyyy')).concat('"');
    }

    return isNullOrWhitespace(value) ? '' : '"'.concat(value).concat('"');
  }

  /**
   * Returns operator type name
   * @param {ViewFilterInstanceSimple} f
   * @returns {string}
   */
  getFilterOperator(f: ViewFilterInstanceSimple): string {
    const config: ViewConfiguration = this.listService.getConfiguration();
    const filterManager: ViewFilterManagerSimple = this.listService.getFilterManagerSimple();

    const op: string = ViewFilterSimpleOperatorType[f.Operator];
    return getInSafe(filterManager, fm => fm.OperatorConfiguration[op].Label, f.Operator.toString()).toLowerCase();
  }

  /**
   * Returns operator type name
   * @param {ViewFilterInstanceSimple} f
   * @returns {string}
   */
  getFilterNegate(f: ViewFilterInstanceSimple): string {
    if (f.Negate) {
      return 'NO';
    }

    return '';
  }

  /**
   * Returns operator type id
   * @param {ViewFilterInstanceSimple} f
   * @returns {string}
   */
  getFilterOperatorId(f: ViewFilterInstanceSimple): string {
    return ViewFilterSimpleOperatorType[f.Operator];
  }

  /**
   * Remove filter handler
   * @param {string} filter
   */
  removeFilter(filter: string): void {
    this.listService.removeFilter(filter);
  }

  /**
   * Check if a filter is of type date
   * @param {ViewFilterInstanceSimple} f
   * @returns {boolean}
   */
  private checkIfDate(f: ViewFilterInstanceSimple): boolean {
    const filterType: ViewsFieldTypeEnum = (this.listService.getConfiguration().Fields[f.FilterId] as ViewFieldData).TypeConfiguration.Type;
    return filterType === ViewsFieldTypeEnum.Date;
  }
}
