import {
  IViewResultCell,
  ViewFieldMetadata,
  ViewResultRow,
  ViewsFieldTypeConfigEnumOption
} from '../../core/models/ETG_SABENTISpro_Application_Core_models';

export class ViewUtils {
  public static BooleanOptions : { [id: string]: ViewsFieldTypeConfigEnumOption } = {
    '0': {
      Id: "0",
      Match: 0,
      DisplayName: "FalseText"
    } as ViewsFieldTypeConfigEnumOption,
    '1': {
      Id: "1",
      Match: 1,
      DisplayName: "TrueText"
    } as ViewsFieldTypeConfigEnumOption,
  }

  public static GetFieldMetadata(row: ViewResultRow, metadataName: string): ViewFieldMetadata {
    if (!row.Metadata) {
      throw new Error('The current row has any metadata')
    }
    if (!row.Metadata.hasOwnProperty(metadataName)) {
      throw new Error(`The metadata ${metadataName} doesn't exists in current metadata row`)
    }
    return row.Metadata[metadataName] as ViewFieldMetadata;
  }

  public static GetFieldMetadataValue<T>(row: ViewResultRow, metadataName: string): T {
    return this.GetFieldMetadata(row, metadataName).RawValue as T;
  }

  public static GetFieldColumn(row: ViewResultRow, columnName: string): IViewResultCell {
    if (!row.Columns) {
      throw new Error('The current row has any columns')
    }
    if (!row.Columns.hasOwnProperty(columnName)) {
      throw new Error(`The Column id ${columnName} doesn't exists in current metadata row`)
    }
    return row.Columns[columnName] as IViewResultCell;
  }

  public static GetFieldColumnValue<T>(row: ViewResultRow, columnName: string): T {
    return this.GetFieldColumn(row, columnName).RawValue as T;
  }
}
