import { FormManagerService } from '../form-manager/form-manager.service';
import { AfterViewInit, ChangeDetectorRef, Directive, Injectable, SkipSelf } from '@angular/core';
import { FrontendFormElementAware } from './formelement.class';
import { IFrontendFormElement } from '../interfaces/field.interface';
import { FrontendFormElementInput } from './formelementinput.class';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

/**
 * Clase base para los wrappers de FormElements, de manera que se puedan.
 *
 * Para que escale correctamente, por defecto cada elemento de formulario en pantalla está
 * DETACHED.
 */
@Directive()
@Injectable()
// tslint:disable-next-line:directive-class-suffix
export abstract class FrontendFormElementWrapper extends FrontendFormElementAware implements AfterViewInit, IFrontendFormElement {

  /**
   * Para deteccion de cambios en detached
   */
  private detectChangesDebouncer: Subject<void> = new Subject<void>();

  /**
   * Deben implementarlo todoso los wrappers para acceder al Control Value Accessor
   */
  abstract formElementInstance(): FrontendFormElementInput;

  /**
   * Get an instance of
   *
   * @param formManagerService
   */
  constructor(protected formManagerService: FormManagerService,
              protected cdRef: ChangeDetectorRef,
              @SkipSelf()
              protected cdRefParent: ChangeDetectorRef) {

    super(formManagerService, cdRef);

    // Los wrappers se actualizan solo si ha habido un elementConfigChanged
    this.formManagerService
        .elementConfigChanged
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(((clientPath: string): void => {
          if (this.config.ClientPath === clientPath) {
            this.forceDetectChanges();
          }
        }).bind(this));


    this.detectChangesDebouncer
        .pipe(
            debounceTime(50),
            takeUntil(this.componentDestroyed$))
        .subscribe(((): void => {
              // Hacemos esto para que el HOSTBINDING del componente también se actualice :(
              // es un bug en angular
              // @see https://github.com/angular/angular/issues/22560
              this.cdRef.reattach();
              this.cdRefParent.detectChanges();
              this.cdRef.detach();
            }
        ).bind(this));
  }

  /**
   * Used by the form manager service when all the form needs to be CDRef.detecthChanges()
   */
  reattachChangeDetector(): void {
    this.cdRef.reattach();
  }

  /**
   * Used by the form manager service when all the form needs to be CDRef.detecthChanges()
   */
  detachChangeDetector(): void {
    this.cdRef.detach();
  }

  /**
   * Detectar cambios
   */
  detectChanges(): void {
    this.detectChangesDebouncer.next();
  }

  /**
   *
   */
  forceDetectChanges(): void {
    this.detectChangesDebouncer.next();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  initializeDynamicComponent(params: any): void {
  }
}
