import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ReplaySubject, Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';
import { PrimeUtils } from '../utils/prime.utils';
import { ICenterContext } from './interfaces/center-context.interface';
import { ICompanyContext } from './interfaces/company-context.interface';
import { filter, map, takeUntil } from 'rxjs/operators';
import { DestroyableObjectTrait } from '../utils/destroyableobject.trait';
import { CommandService } from '../../core/commands/command.service';
import { backendTypeMatch } from '../utils/typescript.utils';
import { IResultCollector } from '../../core/commands/resultcollector.interface';
import { ContextService } from '../../core/services/ETG_SABENTISpro_Application_Core_context.service';
import { CoreContextSetCommand } from '../../core/models/ETG_SABENTISpro_Application_Core_models';
import { SabentisContext } from '../../core/models/ETG_SABENTISpro_Application_Modules_models';

@Injectable({
  providedIn: 'root'
})
export class AppContextService extends DestroyableObjectTrait {

  private contextSubject$ = new ReplaySubject<{ [id: string]: string }>(1);

  private PreContextSubject$ = new Subject<{ [id: string]: string }>();

  private PostContextSubject$ = new Subject<{ [id: string]: string }>();

  constructor(
    private contextService: ContextService,
    private commandService: CommandService,
    public router: Router
  ) {
    super();
    this.registerUpdateContextFromCommands();

    const existingContext: any = this.GetContext();

    // Emitimos el estado incial del contexto, por si está almacenado en frontend...
    if (existingContext) {
      this.contextSubject$.next(existingContext);
    }
  }

  /**
   * Returns an observable that emits an event on context changes.
   *
   * @returns { Observable<Object> }
   */
  get contextChanged(): Observable<{ [id: string]: string }> {
    return this.contextSubject$.asObservable();
  }

  /**
   * Returns an observable that emits an event on context changes. Only for System Operations.
   *
   * @returns { Observable<Object> }
   */
  get ContextBeforeChanged(): Observable<{ [id: string]: string }> {
    return this.PreContextSubject$.asObservable();
  }

  /**
   * Returns an observable that emits an event on context finish the changes. Only for System Operations.
   *
   * @returns { Observable<Object> }
   */
  get ContextAfterChanged(): Observable<{ [id: string]: string }> {
    return this.PostContextSubject$.asObservable();
  }

  /**
   * getter for the context
   * @returns {T} Type of Context
   */
  GetContext<T>(): T {
    const context: T = JSON.parse(sessionStorage.getItem('context')) as T;
    if (isNullOrUndefined(context)) {
      console.error('Se está intentado acceder al contexto NULL');
    }
    return context;
  }

  /**
   * This method returns the company id selected in the context
   * @returns {string}: company id
   */
  getContextCompanyId(): string {
    const context: SabentisContext = this.GetContext<SabentisContext>();
    if (context && context.selectedCompany) {
      return context.selectedCompany;
    }
    return null;
  }


  /**
   * This method returns if there are any company selected in the context
   * @returns {string}: company id
   */
  anyCompanyInContext(): boolean {
    const companyId: string = this.getContextCompanyId();
    if (!isNullOrUndefined(companyId) && !PrimeUtils.IsDefaultGuid(companyId)) {
      return true;
    }
    return false;
  }

  /**
   * This method returns the center id selected in the context
   * @returns {string}: center id
   */
  getContextCenterId(): string {
    const context: SabentisContext = this.GetContext<SabentisContext>();
    if (context && context.selectedCenter) {
      return context.selectedCenter;
    }
    return null;
  }

  /**
   * This method returns the company Object {Key: string, name: string} selected in the context
   * @returns {string}: company
   */
  getContextCompany(): ICompanyContext {
    const context: SabentisContext = this.GetContext<SabentisContext>();
    if (!context || !context.hasOwnProperty('selectedCompany')) {
      return null;
    }
    if (PrimeUtils.IsDefaultGuid(context.selectedCompany)) {
      return null;
    }
    return {
      id: context.selectedCompany,
      name: context.selectedCompanyLabel,
      cif: context.selectedCompanyCIF,
      location: context.selectedCompanyLocation,
      path: context.selectedCompanyPath,
      companyconfig: context.CurrentCompanyConfiguration
    };
  }

  /**
   * This method returns the center Object {Key: string, name: string} in the context
   * @returns {string}: center
   */
  getContextCenter(): ICenterContext {
    const context: SabentisContext = this.GetContext<SabentisContext>();
    if (!context || !context.hasOwnProperty('selectedCenter')) {
      return null;
    }
    if (PrimeUtils.IsDefaultGuid(context.selectedCenter)) {
      return null;
    }
    return {
      id: context.selectedCenter,
      selectedCenterLabel: context.selectedCenterLabel,
      selectedCenterSecondaryLabel: context.selectedCenterSecondaryLabel,
      location: context.selectedCenterLocation,
      path: context.selectedCenterPath,
    };
  }

  removeContextFromSessionStorage(): void {
    sessionStorage.removeItem('context');
  }

  /**
   * This method returns if exists context in the application.
   * @returns {boolean}
   */
  private existsContext(): boolean {
    return sessionStorage.getItem('context') !== null;
  }

  /**
   * Subscribes the actual service to the `CommandService`, filtering by
   * `CoreCommands.CONTEXT` to update the context on the application.
   */
  private registerUpdateContextFromCommands(): void {
    this.commandService
      .CommandObservable
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter((obj: any) => backendTypeMatch(CoreContextSetCommand.$type, obj.Argument)),
        map((obj) => obj as IResultCollector<CoreContextSetCommand, (() => Promise<boolean>) | Observable<boolean>>)
      )
      .subscribe((next) => {
        next.AddResult(() => {

          console.debug('📄 Context updated from commands.')
          const serializedContext: string = next.Argument.Context;
          const contextObject: { [id: string]: string } = JSON.parse(serializedContext) as { [id: string]: string };

          if (this.PreContextSubject$) {
            this.PreContextSubject$.next(contextObject);
          }

          sessionStorage.setItem('context', serializedContext);

          if (this.PostContextSubject$) {
            this.PostContextSubject$.next(contextObject);
          }

          if (this.contextSubject$) {
            this.contextSubject$.next(contextObject);
          }

          return Promise.resolve(true);
        });
      });
  }
}
